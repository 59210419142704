.contactpageWrapper {
  background-color: var(--lightgrey-color);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-image: url('https://ik.imagekit.io/xrqkeii37mq/Enteeon/mainPhotos/contactusBg_xAom_Qoik.JPG');
  background-size: cover;
  background-position: bottom;
  min-height: calc(100vh - 8rem);
}
.contactpageWrapper .formBlock {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.contactpageWrapper .formBlock .contactpage {
  flex: 1;
  width: 100%;
  color: var(--white-color);
  padding: 3rem;
  border-radius: 0.3rem;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
}
.contactpageWrapper .formBlock .contactform {
  flex-direction: column;
  flex: 1;
  align-self: center;
  width: 40%;
}
.contactpageWrapper .formBlock .contactpage span {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white-color);
}
.contactpageWrapper .formBlock .contactform .error {
  color: var(--white-color);
}
.contactpageWrapper .formBlock .contactform input {
  margin: 1rem;
  height: 2rem;
  font-size: 1rem;
  width: 80%;
  padding-left: 0.2rem;
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px solid var(--white-color);
  color: var(--white-color);

  /* border-radius: 0.3rem; */
}
.contactpageWrapper .formBlock .contactform input::placeholder {
  color: var(--white-color);
}
.contactpageWrapper .formBlock .contactform button {
  margin-top: 1rem;
  padding: 1rem 2rem;
  border: none;
  background-color: var(--lgrad-color2);
  color: var(--white-color);
  border-radius: 1rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.contactpageWrapper .formBlock .contactform button:hover {
  transform: scale(1.1);
  background-color: var(--hover-green);
}
.contactpageWrapper .detailsBlock {
  display: flex;
  margin-top: 2rem;
  width: 100%;
  padding: 2rem;
  justify-content: space-evenly;
  background-color: var(--smoke-color);
  flex-wrap: wrap;
}
.contactpageWrapper .detailsBlock .details {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
@media screen and (max-width: 780px) {
  .contactpageWrapper .detailsBlock .details {
    min-width: 34%;
  }
  .contactpageWrapper .formBlock {
    width: 100%;
  }
  .contactpageWrapper .formBlock .contactform {
    
    width: 80%;
  }
  .contactpageWrapper .formBlock .contactform input {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .contactpageWrapper .detailsBlock {
    flex-direction: column;
  }
  .contactpageWrapper .detailsBlock .details {
    padding: 1rem;
  }
  .contactpageWrapper .formBlock {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .contactpageWrapper .formBlock .contactpage {
    font-size: 2rem;
    padding: 1rem;
  }
  .contactpageWrapper .formBlock .contactform {
    width: 100%;
  }
  .contactpageWrapper {
    padding: 0.25rem;
  }
}