.wrapper{
  background: var(--white-color);
  height: auto;
  min-height: calc(100vh - 8rem);
  flex-direction: column;
  /* margin-top: 10%; */
}

.wrapper .problemSection {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  height: 100%;
  /* min-height: 80vh; */
}

.wrapper .problemSection .card {
  margin: 15px 30px;
  width: 300px;
  height: 400px;
  text-align: center;
  perspective: 1000px;
}

.wrapper .problemSection .card__inner {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  /* cursor: pointer; */
  position: relative;
}

.wrapper .problemSection .card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
}

.wrapper .problemSection .card__face--front {
  background-image: linear-gradient(to bottom right, #42c704, #010402cb);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .problemSection .card__face--front h2 {
  color: #FFF;
  font-size: 32px;
}

.wrapper .problemSection .card__face--back {
  background-color: var(--light);
}

.wrapper .problemSection .card__content {
  width: 100%;
  height: 100%;
}

.wrapper .problemSection .card__header {
  position: relative;
  padding: 70px 30px 50px;
  z-index: 5;
}

.wrapper .problemSection .card__header:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom left, var(--primary) 10%, var(--secondary) 115%);
  z-index: -1;
  border-radius: 0px 0px 50% 0px;
}

.wrapper .problemSection .pp {
  /* display: block; */
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #FFF;
  border: 5px solid #FFF;
  object-fit: cover;
}

.wrapper .problemSection .pp img {
  height: 50px;
  width: 50px;
}

.wrapper .problemSection .card__header h3 {
  position: absolute;
  top: 35px;
  left: 101px;
  color: #FFF;
  font-size: 1.9rem;
  z-index: 5;
  text-transform: uppercase;
}

.wrapper .problemSection ul li {
  list-style-type: none;
    margin: 15px 0;
}

.wrapper .problemSection .card__body {
  padding: 30px;
}

.wrapper .problemSection .card__body h3 {
  /* color: var(--dark); */
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

.wrapper .problemSection .card__body p {
  color: var(--dark);
  font-size: 18px;
  line-height: 1.1;
  text-align: center;

}


.wrapper h1{
  font-size: 52px;
  /* color: white; */
  margin: 2rem 0rem;
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
}
