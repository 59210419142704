:root {
  --white-smoke: rgb(245, 245, 245);
  --white-color: #fff;
  --black-color: #000;
  --navbar-shadow: #999;
  --inner-navcolor: rgb(206, 206, 206);
  --lgrad-color1: #1e6d37;
  --lgrad-color2: #137549;
  --yellow-color: rgb(255, 255, 0);
  --hover-green: rgb(14, 92, 38);
  --green-color: rgb(0, 128, 0);
  --facebook-color: #3b5998;
  --twitter-color: #00aced;
  --instagram-color: #e1306c;
  --linkedin-color: #0077b5;
  --dark-green-color: #294d01;
  --lightgrey-color: #ccc;
  --primary: #76ec3f;
  --secondary: #02471dcb;
  --dark: #212121;
  --light: #F3F3F3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: var(--white-smoke);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: var(--black-color);
}
.facebook {
  color: var(--facebook-color);
}
.instagram {
  color: var(--instagram-color);
}
.twitter {
  color: var(--twitter-color);
}
.linkedin {
  color: var(--linkedin-color);
}
.button {
  cursor: pointer;
}
.content {
  line-height: 2.5rem;
  word-spacing: 0.2rem;
}
