.socialConnect {
    color: var(--black-color);
    padding-right: 2rem;
}
.socialConnect .heading {
    font-weight: 300;
    padding-right: 2rem;
}
.socialConnect .socialIcons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
}
.socialConnect .socialIcons a {
    height: 1.5rem;
    width: 1.5rem;
}
.socialConnect .socialIcons a svg {
    font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
    .socialConnect {
        padding: 1rem;
    }
}
    