#footer {
  bottom: 0;
  width: 100%;
}
#footer > div {
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  #footer > div {
    flex-direction: column;
  }
}
