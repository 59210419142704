.feedContainer {
  display: flex;
  color: var(--black-color);
  flex-direction: column;
  width: 100%;
}
.feedContainer .aboutProds {
  width: 100%;
  position: relative;
  height: calc(100vh - 7rem);
  background-image: url('/src/Components/ProductPages/OrganicFeed/IMG_20220620_151945.jpg');  
  background-size: cover;
  font-size: 5rem;
  font-weight: 300;
  flex-direction: column;
  text-align: center;
  z-index: 2;
  align-items: flex-start;
}
.feedContainer .aboutProds:before {
  content: '';
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}

.feedContainer .aboutProds .textArea {
  position: relative;
  color: #ffffff;
  font-size: 14rem;
  line-height: 1.5;
  text-align: center;
}

.feedContainer .aboutProds p {
  font-size: 2rem;
  font-weight: 300;
  color: var(--white-color);
  padding: 1rem;
  margin: 0 1rem;
}

.feedContainer .aboutProds .largeText {
  font-weight: 900;
  font-style: italic;
  font-size: 4rem;
}

.feedContainer .ourProducts {
  display: flex;
  flex-direction: column;
  color: var(--black-color);
  padding: 3rem 3rem 0rem 3rem;
}
.feedContainer .ourProducts h3 {
  font-size: 3.5rem;
  font-weight: 300;
  padding-bottom: 3rem;
}
.feedContainer .ourProducts .products {
  display: flex;
  flex-direction: column;
}
.feedContainer .ourProducts .products .productrow {
  flex-direction: row;
  justify-content: space-evenly;
  height: fit-content;
  padding: 3rem;
}
.feedContainer .ourProducts .products .productrow:nth-child(2n) {
  display: flex;
  flex-direction: row-reverse;
}
.feedContainer .ourProducts .products .productrow h4 {
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 1rem;
  text-align: center;
}
.feedContainer .ourProducts .products .productrow p, .feedContainer .ourProducts .products .productrow ul li  {
  padding-top: 1rem;
  text-align: justify;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.8rem;
}
.feedContainer .ourProducts .products .productrow ul {
  margin-left: 2rem;
}
.feedContainer .ourProducts .products .productrow img {
    height: 20rem;
    border-radius: 50%;
    width: 20rem;
}
.feedContainer .ourProducts .products .productrow span {
  height: 15rem;
  flex: 1;
}
/* .feedContainer .ourProducts .products .fishFeed img {
  width: 10rem;
  height: auto;
}
.feedContainer .ourProducts .products .fishFeed p {
  justify-content: flex-end;
} */
.feedContainer .ourProducts .products .productrow .content {
  flex: 1;
}

.feedContainer .contact-us-banner {
  background-color: green;
  background-image: url('https://images.unsplash.com/photo-1591530712751-96e6f5ad73ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80');
  width: 100%;
  height: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.feedContainer .contact-us-banner p {
  color: white;
  font-size: 3rem;
  padding: 1rem 0;
  margin: 1rem;
  font-weight: 800;
}

/* CSS */
.feedContainer .btn {
  font-size: 1rem;
  background: rgba(243, 211, 31, 0.993);
  font-weight: bold;
  width: 10rem;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s box-shadow;
}
.feedContainer .btn:hover,
.feedContainer .btn:focus,
.feedContainer .btn:active {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}

.feedContainer td img {
  height: 2.5rem;
  width: 2.5rem;
}

.feedContainer .header_fixed {
  height: 34rem;
  width: 35rem;
}

.feedContainer .header_fixed thead th {
  position: sticky;
  top: 0;
  background-color: var(--black-color);
  color: var(--white-color);
  font-weight: 800;
}

.feedContainer tr:nth-child(even) {
  background-color: #dddddd;
}

.feedContainer table {
  border-collapse: collapse;
  text-align: center;
}

.feedContainer .table-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.feedContainer .nutri-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedContainer .nutri-content p {
  padding: 0.5rem;
  padding-left: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.feedContainer .table-content h4 {
  font-size: 2rem;
  padding: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  text-align: center;
}

.feedContainer th,
td {
  border-bottom: 1px solid #dddddd;
  padding: 1.2rem 2rem;
  font-size: 1rem;
}

.feedContainer tr:hover td {
  cursor: pointer;
}

.feedContainer .animal-info {
  display: flex;
  flex-direction: column;

  margin: 2rem 0;
  width: 100%;
  /* background-color: rgb(228, 228, 228); */
}

.feedContainer .blog-post {
  display: flex;
  flex-direction: row;
  margin: 2rem 5rem;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.feedContainer .blog-post-img {
  flex: 1;
  padding: 2rem 2rem 2rem 2rem;
}

.feedContainer .blog-post-img > img {
  height: 15rem;
  width: 20rem;
  border-radius: 0.3rem;
  object-fit: cover;
  transform: translate(-50px);
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.5);
}

.feedContainer .blog-post-info {
  flex: 2;
  padding: 2rem;
}

.feedContainer .blog-post-title h2 {
  color: black;
  text-transform: uppercase;
}
.feedContainer .blog-post-desc {
  line-height: 2rem;
}

.feedContainer .animal-heading {
  padding-top: 2rem;
}

.feedContainer .textArea-animal {
  padding: 0 10rem;
  line-height: 2rem;
}

@media only screen and (max-width: 992px) {
  .feedContainer .blog-post {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .feedContainer .aboutProds p {
    font-size: 1.2rem;
  }
  .feedContainer .aboutProds .largeText {
    font-size: 3rem;
  }

  .feedContainer .blog-post-img {
    flex: 1;
    padding: 0rem 1rem 0rem 1rem;
  }
  .feedContainer .blog-post-img > img {
    height: 10rem;
    width: 15rem;
    border-radius: 0.3rem;
    object-fit: cover;
    transform: translate(-20px);
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.5);
  }

  .feedContainer .blog-post-info {
    flex: 2;
    padding: 0rem 1rem 2rem 1rem;
  }
}
@media screen and (max-width: 768px) {
  .feedContainer .aboutProds {
    height: auto;
    padding-bottom: 1rem;
  }
  .feedContainer .whatWeDo .answer {
    max-width: 100%;
  }

  .feedContainer .table-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feedContainer .nutri-content p {
    padding-bottom: 1rem;
  }

  .feedContainer th,td {
  padding: 2rem 1rem;
  }
  .feedContainer .companyName {
    padding-left: 1rem;
    font-size: 3.5rem;
  }
  .feedContainer .companyName p {
    width: 85%;
    font-size: 1rem;
  }
  .feedContainer .whatWeDo {
    padding: 2rem;
  }
  .feedContainer .ourProducts {
    padding: 2rem 0rem;
  }
  .feedContainer .ourProducts h3 {
    font-size: 2rem;
    margin: 0.5rem 1rem;
    text-align: center;
  }
  .feedContainer .ourProducts .products,
  .feedContainer .ourProducts .products .productrow:nth-child(n) {
    flex-direction: column;
  }
  .feedContainer .ourProducts .products .productrow h4 {
    font-size: 1.5rem;
  }
  .feedContainer .ourProducts .products .productrow img,
  .feedContainer .ourProducts .products .productrow span {
    height: 12rem;
    border-radius: 50%;
    width: 12rem;
  }

  .feedContainer .blogs {
    padding: 0.5rem;
  }
  .feedContainer .whatWeDo .blockquote {
    flex-direction: column;
  }
  .feedContainer .header_fixed{
    width: 15rem;
  }
}
@media only screen and (max-width: 425px) {
  .feedContainer .blog-post {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .feedContainer .ourProducts .products .productrow {
    padding: 2rem;
  }
  .feedContainer .table-content h4 {
    padding: 1.5rem;
  }
  .feedContainer .blog-post-img {
    flex: 1;
    padding: 0rem 1rem 0rem 1rem;
  }

  .feedContainer .blog-post-img > img {
    height: 10rem;
    width: 15rem;
    border-radius: 0.3rem;
    object-fit: cover;
    transform: translate(-20px);
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.5);
  }

  .feedContainer .blog-post-info {
    flex: 2;
    padding: 0rem 1rem 2rem 1rem;
  }
  .feedContainer .ourProducts .products .productrow p, .feedContainer .ourProducts .products .productrow ul li {
    padding-top: 1rem;
    text-align: justify;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.8rem;
  }
  .feedContainer .ourProducts .products .productrow ul {
    margin-left: 1rem;
  }
}
