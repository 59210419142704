nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.225rem 2.25rem -6px var(--navbar-shadow);
  background-color: var(--white-color);
}
nav .logo img {
  height: 4rem;
  width: 4rem;
  margin-right: 0.5rem;
}
nav .logo {
  display: flex;
  align-items: center;
  color: var(--black-color);
  font-size: 2em;
  letter-spacing: 2px;
  margin-left: 0.5rem;
}

.nav-links > li {
  position: relative;
  list-style: none;
  padding-right: 5rem;
  width: 100%;
  height: 100%;
}

nav .nav-links {
  height: 100%;
}

nav .nav-links li .inner-nav-links {
  position: absolute;
  top: 5rem;
  right: 5rem;
  width: 8rem;
  display: none;
  flex-direction: column;
}

nav .nav-links li:hover .inner-nav-links {
  display: flex;
}

nav .nav-links li .inner-nav-links li {
  padding: 2rem 1rem;
  background-color: var(--inner-navcolor);
  width: 150%;
}
nav .nav-links li .inner-nav-links li:hover {
  background-color: var(--lgrad-color2);
}
nav .nav-links li .inner-nav-links li:hover > a {
  color: var(--white-color);
}
.nav-links > li a {
  position: relative;
  display: inline-block;
  letter-spacing: 2px;
  font-weight: 800;
}

/* .nav-links > li > a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
} */

.nav-links > li:hover > a {
  color: var(--green-color);
  transition: 0.25s;
}

.nav-links > li > a:after:hover {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: var(--black-color);
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1024px) {
  nav {
    height: 5rem;
    /* padding: 0.93rem 1.25rem; */
  }
  .nav-links > li {
    padding-right: 1rem;
  }
  .nav-links > li > a {
    position: relative;
    display: inline-block;
    padding: 0 0.75rem;
    text-decoration: none;
    color: var(--black-color);
    letter-spacing: 1px;
    font-weight: 800;
  }
  nav .nav-links li .inner-nav-links {
    right: 2rem;
    width: 7rem;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  nav {
    height: 5rem;
    padding: 0.313rem 0.625rem;
  }
  nav .logo {
    position: relative;
    left: 0;
    margin-left: 0.2rem;
  }
   .nav-links {
    position: absolute;
    right: 0;
    overflow-y: scroll;
    top: 5rem;
    font-size: 1.25rem;
    background-image: linear-gradient(315deg, var(--lgrad-color1) 0%, var(--lgrad-color2) 74%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    transform: translateX(100%);
  }
  nav .nav-links {
    height: 92vh;
  }
  .nav-links > li > a:hover {
    color: var(--yellow-color);
    transition: 0.25s;
  }
  nav .nav-links li:hover .inner-nav-links {
    display: none;
  }

  .nav-links > li {
    opacity: 0;
    height: 5rem;
    text-align: center;
    border-bottom: 1px solid white;
  }
  .nav-links > li:hover {
    background-color: var(--hover-green);
  }
  .nav-links > li > a {
    color: var(--white-color);
    letter-spacing: 2px;
  }
  .burger {
    display: block;
  }
}

.nav-active {
  transform: translateX(0%);
  transition: transform 0.3s ease-in;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(3.125rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 425px) {
  nav .logo {
    font-size: 1.5rem;
  }
  nav .logo img {
    height: 3rem;
    width: 3rem;
  }
}